@font-face {
    font-family: 'mpolis';
    src: url('fonts/metropolis-bold-webfont.woff2') format('woff2');
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: 'mpolis';
    src: url('fonts/metropolis-light-webfont.woff2') format('woff2');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: 'mpolis';
    src: url('fonts/metropolis-medium-webfont.woff2') format('woff2');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'mpolis';
    src: url('fonts/metropolis-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 40px;
    background: #000;
    z-index: 20000
}

.page {

}

.service-header {
    width: 100%;
    height: 100vh;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 150px 0 100px 0
}

.service-header .title {
    font-size: 48px;
    font-weight: bold;
    position: relative;
    z-index: 2;
    margin-top: 10px;
}

.service-header .subtitle {
    font-size: 22px;
    font-weight: 300;
    margin: 20px auto;
    max-width: 900px;
    position: relative;
    z-index: 2
}

.service-header {
    min-height: 300px;
    padding: 70px 0 150px 0
}

.service-header .title {
    font-size: 36px
}

.service-header .subtitle {
    font-size: 20px
}

.service-header .circle-animation {
    left: 50%;
    -webkit-transform: translate(-50%) !important;
}

.service-header.win {
    background-color: #000
}

.service-header.win:before {
    position: absolute;
    content: "";
    display: block;
    left: -100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
    background: url(/src/images/background-windows.png) repeat;
    opacity: 1;
    -webkit-animation: bkgmoveUp240 180s linear infinite;
    animation: bkgmoveUp240 180s linear infinite
}

.service-header.win .title {
    color: #fff
}

.service-header.win .subtitle {
    color: #fff
}

.circle-animation {
    position: absolute;
    width: 80%;
    height: 80%;
    max-width: 300px;
    border-radius: 100%;
    -webkit-animation: circleAnimation 1s linear;
    animation: circleAnimation 1s linear;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%
}

.circle-animation:before, .circle-animation:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block
}

.circle-animation:before {
    background-image: url(/src/images/circle-animation-1.png);
    background-size: contain;
    -webkit-animation: circleAnimation 15s linear infinite;
    animation: circleAnimation 15s linear infinite
}

.circle-animation:after {
    background-image: url(/src/images/circle-animation-2.png);
    background-size: contain;
    -webkit-animation: circleAnimation 15s linear reverse infinite;
    animation: circleAnimation 15s linear reverse infinite
}

.service-big-icon {
    top: 10%;
    left: 50%;
    -webkit-transform: translate(-50%) !important;
    position: absolute;
    width: 40%;
    height: 80%;
    max-width: 150px;
    background-image: url(/src/images/background-win.png);
    background-size: contain;
    transform-origin: 50% 50%;



}


body {
    background: #fff;
    font-size: 16px;
    font-family: mpolis, sans-serif;
    color: #666;
    margin: 0;
    padding: 0;
    padding-top: 0;
    width: 100%;
    line-height: normal
}

.body {
    width: 100%;
    margin: auto;
    position: relative;

}


.body:before, .body:after {
    content: " ";
    display: table;
    visibility: hidden
}

.body:after {
    clear: both
}


@-webkit-keyframes waveAnimation {
    0% {
        background-position: 0 100%, 0 100%, 0 100%;
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }
    50% {
        -webkit-transform: scaleY(.55);
        transform: scaleY(.55)
    }
    100% {
        background-position: -1920px 100%, -3840px 100%, -5760px 100%;
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }
}

@keyframes waveAnimation {
    0% {
        background-position: 0 100%, 0 100%, 0 100%;
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }
    50% {
        -webkit-transform: scaleY(.55);
        transform: scaleY(.55)
    }
    100% {
        background-position: -1920px 100%, -3840px 100%, -5760px 100%;
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }
}

.wave-animation {
    background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0) 50px)
}

.wave-animation, .wave-animation > div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%
}

.wave-animation div:nth-child(1) {
    background: url(/src/images/wave-top.png) repeat-x;
    opacity: .7;
    -webkit-animation: waveAnimation 10s linear infinite 0s;
    animation: waveAnimation 10s linear infinite 0s;
    mix-blend-mode: hard-light
}

.wave-animation div:nth-child(2) {
    background: url(/src/images/wave-mid.png) repeat-x;
    opacity: .6;
    -webkit-animation: waveAnimation 20s linear infinite -8s;
    animation: waveAnimation 20s linear infinite -8s;
    mix-blend-mode: hard-light
}

.wave-animation div:nth-child(3) {
    background: url(/src/images/wave-bot.png) repeat-x;
    opacity: .4;
    -webkit-animation: waveAnimation 30s linear infinite -4s;
    animation: waveAnimation 30s linear infinite -4s;
    mix-blend-mode: hard-light
}

.wave-animation.darkblue {
    background: linear-gradient(to top, #1a2545, rgba(26, 37, 69, 0) 50px)
}

.wave-animation.darkblue div {
    mix-blend-mode: normal
}

.wave-animation.darkblue div:nth-child(1) {
    background-image: url(/src/images/wave-top-darkblue.png)
}

.wave-animation.darkblue div:nth-child(2) {
    background-image: url(/src/images/wave-mid-darkblue.png)
}

.wave-animation.darkblue div:nth-child(3) {
    background-image: url(/src/images/wave-bot-darkblue.png)
}

@-webkit-keyframes circleAnimation {
    from {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
    to {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes circleAnimation {
    from {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
    to {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

.topbar {
    height: 40px;
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #40b7ff), color-stop(50%, #003656));
    background: linear-gradient(to right, #40b7ff 50%, #003656 50%)
}

.topbar .inner {
    background-color: #003656
}

.topbar .tag {
    background-color: #40b7ff;
    line-height: 40px;
    font-weight: bold;
    font-size: 14px;
    position: relative;
    float: left;
    color: #fff;
    padding-right: 2px
}

.topbar .tag:after {
    content: "";
    display: block;
    position: absolute;
    right: -20px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #40b7ff;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    -webkit-box-shadow: -1px 0 0 #40b7ff;
    box-shadow: -1px 0 0 #40b7ff
}

.topbar .tag:before {
    content: "";
    display: block;
    position: absolute;
    left: -70px;
    top: 0;
    width: 70px;
    height: 40px;
    background: linear-gradient(105deg, #40b7ff 10px, rgba(255, 255, 255, 0.5) 40px, #40b7ff 47px)
}

.topbar .promoline {
    float: left;
    line-height: 30px;
    padding: 5px 0 5px 30px;
    font-size: 15px;
    font-weight: bold;
    color: #d0edff
}

.topbar .promoline strong, .topbar .promoline b {
    color: #fff
}

.body {
    margin-right: 20px;
    margin-left: 20px;
    width: auto;
    min-width: 320px
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center center
}

*:after, *:before {
    content: normal;
    display: none;
    background-repeat: no-repeat;
    background-position: center center
}

.topbar .promoline {
    padding-left: 30px;
    white-space: nowrap;
    float: none;
    overflow: auto
}

@-webkit-keyframes bkgmoveUp240 {
    from {
        background-position: 0 0
    }
    to {
        background-position: -240px -4800px
    }
}

@keyframes bkgmoveUp240 {
    from {
        background-position: 0 0
    }
    to {
        background-position: -240px -4800px
    }
}

.bottom {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 70px;
    left: 20px;
    right: 20px;
    color: white;
    text-align: left;
    font-size: 17px;
}

.bind-modal-par {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 400px;
    margin-top: -200px;
    margin-left: -200px;
    background: transparent;
    border-radius: 16px;
}

.bind-modal {
    width: 100%;
    height: 100%;
    /*width: 400px;*/
    /*height: 500px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: contain;
    background-image: url("/src/images/bind_bg.png");
    /**/
    /*background: url("/src/images/bind_bg.png")  no-repeat ;*/
}

.address-input {
    margin-top: 250px;
    width: 200px !important;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;


}

.bind-btn {
    border: 1px solid;
    border-radius: 15px !important;
    margin-top: 50px !important;
    width: 280px;
    color: white !important;
    height: 50px !important;
    background-image: linear-gradient(to bottom right, #BE7EF1FF, #5B60F9FF);
}

.mine-btn {
    border-radius: 5px !important;
    color: white !important;
    height: 50px !important;
    background-color: #E16500FF;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    margin-top: 20px;
}

.connect-wallet {
    background-image: linear-gradient(to bottom right, #2eb5f4, #7121ff);
    border-radius: 10px;
    /*width: 100px;*/
    padding: 8px 5px;
    color: white;
    float: right;
    z-index: 4;
    /*word-wrap: inherit;*/
    /*max-lines: 1;*/
    margin-top: -25px;
    /*position: relative;*/
}

.my-list {
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    display: flex;
    flex-direction: column;
    /*fl*/
    /*align-items: center;*/
    /*justify-content: center;*/
    width: 70%;
    max-width: 400px;
    max-height: 400px;
    /*margin-top: -200px;*/
    /*margin-left: -200px;*/
    /*background: transparent;*/
    margin-top: 40px;
    border-radius: 5px;
    /*border: 1px solid red;*/
    overflow: scroll;
}

.list-modal {
    /*width:100%;*/
    /*height:100%;*/
    /*display:flex;*/
    flex-direction: column
}

.list-modal > .adm-mask-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
}

.list-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.list-close {
    position: absolute;
    right: 5px;
    /*position: relative;*/
    /*float: right;*/
}

.list-column {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    margin-top: 10px;
    background-image: url("/src/images/top.png");
    background-size: 100% 100%;
}
.list-column-item{
    text-align: center;
    width: 100%;
}
.list-foot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;
    background-image: url("/src/images/bottom.png");
    background-size: 100% 100%;
}

.list-foot-item {
    border: 1px solid #00adfd;
    width: 30px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #00adfd;
    text-align: center;
}

.list-body {
    height: 400px;
}

.list-body-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    text-align: center;
    height: 30px;
}

.list-body-item-column {
    width: 100%;
    text-align: center;
}